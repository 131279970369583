import { extendTheme, ThemeOverride } from "@chakra-ui/react";
import { Button } from "./componentThemes/Button";
import Input from "./componentThemes/Input";
import Heading from "./componentThemes/Heading";
import Text from "./componentThemes/Text";
import FormLabel from "./componentThemes/FormLabel";
import Checkbox from "./componentThemes/Checkbox";
import Select from "./componentThemes/Select";

const themeOverride: ThemeOverride = {
  baseStyle: {
    bg: "black",
  },
  fonts: {
    heading: "Monument Extended Bold",
    body: "Roobert Regular",
  },
  config: {
    initialColorMode: "light",
  },
  colors: {
    primaryBrand: "#6F57FF",
    secondaryBrand: "#63EB24",
    darkBackground: "#181617",
    dark: "#181617",
    darkGrey: "#2F2D2E",
    mediumGrey: "#A3A2A2",
    gray1: "#333333",
    gray2: "#4F4F4F",
    gray3: "#828282",
    gray4: "#BDBDBD",
    gray5: "#E0E0E0",
    pureWhite: "#FFFFFF",
    white: "#FFFFFF",
    error: "#EB5757",
    brand: {
      primary: "#6F57FF",
      secondary: "#63EB24",
    },
    grey: {
      dark: "#2F2D2E",
      middark: "#303032",
      mid: "#A3A2A2",
      0: "#D1D0D1",
      4: "#BDBDBD",
    },
  },
  textStyles: {
    body48: {
      fontSize: "48px",
      lineHeight: "150%",
    },
    body36: {
      fontSize: "36px",
      lineHeight: "150%",
    },
    body24: {
      fontSize: "24px",
      lineHeight: "150%",
    },
    body20: {
      fontSize: "20px",
      lineHeight: "150%",
    },
    body18: {
      fontSize: "18px",
      lineHeight: "150%",
    },
    body16: {
      fontSize: "16px",
      lineHeight: "150%",
    },
    body14: {
      fontSize: "14px",
      lineHeight: "150%",
    },
    body12: {
      fontSize: "12px",
      lineHeight: "150%",
    },
    body10: {
      fontSize: "10px",
      lineHeight: "150%",
    },
  },
  components: {
    Button,
    Input,
    Heading,
    Text,
    FormLabel,
    Checkbox,
    Select,
    Container: {
      sizes: {
        xl: {
          maxW: "container.xl",
        },
        lg: {
          maxW: "container.lg",
        },
      },
      defaultProps: {
        size: "xl",
      },
    },
  },
};

export default extendTheme(themeOverride);
