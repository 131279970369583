import React from "react";
import { ReactComponent as StarSVG } from "src/general/components/StarSVG/StarSVG.svg";
import { ReactComponent as WaveSVG } from "src/general/components/WaveSVG/WaveSVG.svg";
import { formatPhoneNumber } from "src/general/utils/formatPhoneNumber";
import { usePhoneNumber } from "src/lib/phoneNumber/phoneNumber-context";
import { Center, Heading, Text } from "src/theme";
import theme from "src/theme/theme";
import {
  TROUBLE_SHOOTING_HEADER,
  TROUBLE_SHOOTING_SUBHEADER,
} from "./constants";

const getSubHeader = (phoneNumber) =>
  TROUBLE_SHOOTING_SUBHEADER.replace(
    "**phone_number**",
    formatPhoneNumber(phoneNumber)
  );

const TroubleShootingHeader = () => {
  const { state } = usePhoneNumber();
  return (
    <>
      <Center mb={8}>
        <StarSVG stroke={theme.colors.pureWhite.toString()} />
      </Center>
      <Heading textAlign="center">{TROUBLE_SHOOTING_HEADER}</Heading>
      <Center my={8}>
        <WaveSVG stroke={theme.colors.secondaryBrand.toString()} />
      </Center>
      <Text mt={2} textAlign="center">
        {getSubHeader(state.phoneNumber)}
      </Text>
    </>
  );
};

export default TroubleShootingHeader;
