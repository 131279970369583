import { FC, ReactElement } from "react";
import DefaultLayout from "./Default";
import AltLayout from "./Alt";

const layouts = {
  default: DefaultLayout,
  alt: AltLayout,
};

type Props = {
  children: ReactElement;
};

const LayoutWrapper: FC<Props> = ({ children, ...rest }) => {
  // to get the text value of the assigned layout of each component
  const Layout = layouts[(children.type as any).layout];
  // if we have a registered layout render children with said layout
  if (Layout != null) {
    return <Layout {...rest}>{children}</Layout>;
  }
  // if not render children with fragment
  return <DefaultLayout {...rest}>{children}</DefaultLayout>;
};

export default LayoutWrapper;
