import React from "react";
import { TroubleshootingHeader } from "src/modules/Troubleshooting";
import { Box } from "src/theme";

const TroubleshootingPage = () => {
  return (
    <Box>
      <TroubleshootingHeader />
    </Box>
  );
};

export default TroubleshootingPage;
