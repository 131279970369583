import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { range } from "src/general/utils/range";
import getDaysInMonth from "date-fns/getDaysInMonth";
import isBefore from "date-fns/isBefore";
import subYears from "date-fns/subYears";

import { CheckIcon } from "@chakra-ui/icons";
import { HStack, Box, Checkbox, Text, Button } from "src/theme";
import { useUpdateBirthday } from "src/lib/cognito/useUpdateBirthday";
import { pp, tos } from "../router/routes";

interface Option {
  value: number;
  label: number;
  message?: string;
}

type Inputs = {
  month: Option;
  day: Option;
  year: Option;
  tos: boolean;
};

let months = range(1, 13).map((thisMonth) => {
  return {
    value: thisMonth,
    label: thisMonth,
  };
});

const getControlStyle = (base) => ({
  ...base,
  padding: "8px 8px 9px 14px",
  borderRadius: 3,
  color: "pureWhite",
  background: "#2F2D2E",
  fontFamily: "RoobertTrial",
  border: "none",
  borderColor: "none!important",
  boxShadow: "none!important",
});

const selectStyles = {
  control: getControlStyle,
  singleValue: () => ({
    color: "#fff",
  }),
  container: (base) => ({
    ...base,
    background: "#2F2D2E",
    color: "#fff",
  }),
  menu: (base) => ({
    ...base,
    background: "#2F2D2E",
    color: "#fff",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#4F4F4F" : "#2F2D2E",
    "&:hover": {
      backgroundColor: "#4F4F4F",
    },
  }),
};

const getOptionLabelFormat = (option, context) => {
  return context.context === "menu" &&
    context.selectValue.length &&
    context.selectValue[0].value === option.value ? (
    <Box display="flex" justifyContent="space-between">
      <>{option.label}</> <CheckIcon />
    </Box>
  ) : (
    option.label
  );
};

const CheckboxLink = {
  fontSize: 14,
  color: "pureWhite",
  textDecoration: "underline",
  cursor: "pointer",
};

const validAge = (month, day, year) => {
  const ageCutoff = subYears(new Date(), 13);
  return isBefore(new Date(year, month - 1, day), ageCutoff);
};

const renderDayOptions = (month, year = new Date().getFullYear()) => {
  let days = month ? getDaysInMonth(new Date(year, month - 1)) : 31;

  const dayOptions = [];
  for (let i = 1; i <= days; ++i) {
    dayOptions.push({
      value: i,
      label: i,
    });
  }
  return dayOptions;
};

const renderYearOptions = () => {
  const endYear = new Date().getFullYear();
  const startYear = endYear - 100;

  const years = [];
  if (startYear <= endYear) {
    for (let i = startYear; i <= endYear; ++i) {
      years.push(i);
    }
  } else {
    for (let i = endYear; i >= startYear; --i) {
      years.push(i);
    }
  }
  years.reverse();
  const yearOptions = [];
  years.forEach((year) => {
    yearOptions.push({
      value: year,
      label: year,
    });
  });
  return yearOptions;
};

const BirthdayForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [dates, setDates] = React.useState([]);
  const { mutate: updateBirthday } = useUpdateBirthday();
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
    setError,
    setValue,
    formState: { isValid },
  } = useForm<Inputs>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<Inputs> = ({
    day: { value: day },
    month: { value: month },
    year: { value: year },
  }) => {
    const agePass = validAge(month, day, year);
    if (!agePass) {
      setError("month", {
        message: "You must be at least 13 years old to register",
      });
      return;
    }
    setLoading(true);
    updateBirthday({ birthday: `${month}/${day}/${year}` }).then(() =>
      setLoading(false)
    );
  };

  const { value: month } = watch("month") || ({} as Option);
  const { value: day } = watch("day") || ({} as Option);
  const { value: year } = watch("year") || ({} as Option);
  let agePass = false;
  if (month && day && year) {
    agePass = validAge(month, day, year);
  }

  useEffect(() => {
    const options = renderDayOptions(month, year);
    setDates(options);
    const validDay = options.find(({ value }) => value === day);
    if (!validDay) {
      setValue("day", null);
    }
  }, [month, year, day, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HStack w="full" mt={10}>
        <Box flex={1}>
          <Controller
            control={control}
            name="month"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                data-testid="month"
                required
                placeholder="Month"
                value={value}
                onChange={onChange}
                formatOptionLabel={getOptionLabelFormat}
                options={months}
                styles={selectStyles}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name="day"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                data-testid="day"
                required
                placeholder="Day"
                value={value}
                onChange={onChange}
                formatOptionLabel={getOptionLabelFormat}
                options={dates}
                styles={selectStyles}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name="year"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                data-testid="year"
                required
                placeholder="Year"
                value={value}
                onChange={onChange}
                formatOptionLabel={getOptionLabelFormat}
                options={renderYearOptions()}
                styles={selectStyles}
              />
            )}
          />
        </Box>
      </HStack>
      {errors.month && (
        <Text data-testid="month-error-message" mt={2} color="error">
          {errors.month.message}
        </Text>
      )}
      <HStack mt={4}>
        <Checkbox
          data-testid="tos-pp-checkbox"
          isFocusable={false}
          {...register("tos", { required: true })}
        />
        <HStack>
          <Text>I agree to the</Text>
          <a data-testid="tos-link" href={tos} target="_blank" rel="noreferrer">
            <Text sx={CheckboxLink}>Terms of Service</Text>
          </a>
          <Text> and </Text>
          <a data-testid="privacy-policy-link" href={pp} target="_blank" rel="noreferrer">
            <Text sx={CheckboxLink}>Privacy Policy</Text>
          </a>
        </HStack>
      </HStack>
      <Button
        data-testid="submit-button"
        mt={4}
        width="full"
        disabled={!isValid || !agePass}
        variant="solid"
        type="submit"
        isLoading={loading}
      >
        Continue
      </Button>
    </form>
  );
};

export default BirthdayForm;
