import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import examples from "libphonenumber-js/examples.mobile.json";
import {
  CountryCode,
  getExampleNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";

import { usePhoneNumber } from "src/lib/phoneNumber/phoneNumber-context";
import { useSendOTP } from "src/lib/cognito/useOTP";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  Box,
  Center,
  Text,
} from "src/theme";
import { withFirst } from "./countryCodes";

interface Option {
  value: CountryCode;
  label: string;
  code: string;
}

type Inputs = {
  countryCode: Option;
  phoneNumber: string;
};

const SHOW_FIRST = ["US"];
const options = withFirst(SHOW_FIRST);

const getDefault = () => ({
  value: options[0].value,
  label: options[0].label,
  code: options[0].code,
});

const getPadding = () => {
  let select = document.querySelector(".chakra-input__left-element");
  let width = select.clientWidth;
  return width;
};

const getWidth = () => {
  let select = document.querySelector("#phoneForm");
  let width = select.clientWidth;
  return width;
};

const LandingForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [padding, setPadding] = React.useState(0);
  const [placeholder, setPlaceholder] = React.useState("(222) 123-4567");
  const { mutate: sendOTP } = useSendOTP();
  const { dispatch } = usePhoneNumber();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<Inputs>({
    mode: "onChange",
    defaultValues: {
      countryCode: getDefault(),
    },
  });

  const countryCode = watch("countryCode");

  useEffect(() => {
    const width = getPadding();
    setPadding(width);

    const phoneNumber = getExampleNumber(countryCode.value, examples);
    setPlaceholder(phoneNumber.formatNational());
  }, [countryCode]);

  const onSubmit: SubmitHandler<Inputs> = ({ countryCode, phoneNumber }) => {
    setLoading(true);
    const phone = parsePhoneNumber(phoneNumber, countryCode.value);
    dispatch({ type: "set", phoneNumber: phone.formatNational() });
    sendOTP({ to: phone.number.toString() }).catch((err) => {
      if (err && err.message) setError(err.message);
      else setError(err);
      setLoading(false);
    });
  };

  return (
    <Box mt={10}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl.Label>Mobile Phone Number</FormControl.Label>
        <Center display="flex" flexDirection="column">
          <Box id="phoneForm" width="full">
            <InputGroup width="full">
              <InputLeftElement data-testid="country-code-select" pr="1" height="full">
                <Controller
                  control={control}
                  name="countryCode"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      defaultValue={options[0]}
                      onChange={onChange}
                      value={value}
                      options={options}
                      formatOptionLabel={(option, context) => {
                        return context.context === "value"
                          ? `${option.value} ${option.code}`
                          : `${option.label} ${option.code}`;
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: "none",
                          background: "#2F2D2E",
                          color: "#fff",
                          borderColor: "none!important",
                          boxShadow: "none!important",
                          fontFamily: "RoobertTrial",
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: "#fff",
                        }),
                        singleValue: () => ({
                          color: "#fff",
                        }),
                        option: (styles, state) => ({
                          ...styles,
                          backgroundColor: state.isSelected
                            ? "#4F4F4F"
                            : "#2F2D2E",
                          "&:hover": {
                            backgroundColor: "#4F4F4F",
                          },
                        }),
                        container: (base) => ({
                          ...base,
                          background: "#2F2D2E",
                          color: "#fff",
                        }),
                        menu: (base) => ({
                          ...base,
                          background: "#2F2D2E",
                          color: "#fff",
                          width: getWidth(),
                        }),
                      }}
                    />
                  )}
                />
              </InputLeftElement>

              <Input
                pl={padding}
                data-testid="phone-input"
                type="tel"
                _autofill={{
                  color: "pureWhite!important",
                  bg: "#2F2D2E!important",
                  fontFamily: "RoobertTrial",
                  outline: "none",
                  borderColor: "gray5",
                  "webkitBoxShadow": "none",
                  boxShadow: "none",
                }}
                autoComplete="off"
                placeholder={placeholder}
                {...register("phoneNumber", {
                  required: true,
                  validate: (value) =>
                    isValidPhoneNumber(value, countryCode.value),
                })}
              />
            </InputGroup>
          </Box>
          {error && (
            <Text textAlign="center" mt={2} color="error">
              {error}
            </Text>
          )}
        </Center>
        <Button
          data-testid="submit-button"
          mt={4}
          width="full"
          disabled={!isValid}
          variant="solid"
          type="submit"
          isLoading={loading}
        >
          Send Code
        </Button>
      </form>
    </Box>
  );
};

export default LandingForm;
