import * as React from 'react'

type Action = {type: 'set', phoneNumber: string} | {type: 'unset'}
type Dispatch = (action: Action) => void
type State = {phoneNumber: string}
type PhoneNumberProviderProps = {children: React.ReactNode}

const PhoneNumberStateContext = React.createContext<
  {state: State; dispatch: Dispatch} | undefined
>(undefined)

function PhoneNumberReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set': {
      return {phoneNumber: action.phoneNumber}
    }
    case 'unset': {
      return {phoneNumber: undefined}
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}
function PhoneNumberProvider({children}: PhoneNumberProviderProps) {
  const [state, dispatch] = React.useReducer(PhoneNumberReducer, {phoneNumber: ""})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return (
    <PhoneNumberStateContext.Provider value={value}>
      {children}
    </PhoneNumberStateContext.Provider>
  )
}
function usePhoneNumber() {
  const context = React.useContext(PhoneNumberStateContext)
  if (context === undefined) {
    throw new Error('usePhoneNumber must be used within a PhoneNumberProvider')
  }
  return context
}
export {PhoneNumberProvider, usePhoneNumber}