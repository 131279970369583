import { useState } from "react";
import Auth from "@aws-amplify/auth";
// import { captureException } from "src/lib/sentry/sentry";
import { FS, FSKey } from "src/general/constants/finiteStates";
import { useAuth } from "./auth-context";

const getUserData = (user) => {
  const { storage, userDataKey } = user;
  if (!storage || !userDataKey || !storage[userDataKey]) {
    return null;
  }
  const userData = JSON.parse(storage[userDataKey]);
  return userData;
};

export const useResend = () => {
  const { state: authState, dispatch: authDispatch } = useAuth();
  const [value, setValue] = useState<FSKey>(FS.IDLE);

  const mutate = async () => {
    setValue(FS.LOADING);
    try {
      const resp = await Auth.signIn(authState.user.username);
      const userData = getUserData(resp);
      authDispatch({ type: "set", user: resp, userData, newUser: false });
      if (resp) {
        setValue(FS.SUCCESS);
      } else {
        throw new Error(await resp.data);
      }
    } catch (e) {
      setValue(FS.ERROR);
      // captureException(e);
    }
  };

  return {
    mutate,
    state: {
      value,
      context: {
        FS,
      },
    },
  };
};
