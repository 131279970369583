import * as React from "react";

type Action =
  | { type: "set"; user: any; userData: any; newUser: boolean }
  | { type: "unset" };
type Dispatch = (action: Action) => void;
type State = { user: any; userData?: any; newUser?: boolean };
type UserProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function AuthReducer(state: State, action: Action) {
  switch (action.type) {
    case "set": {
      return {
        user: action.user,
        userData: action.userData,
        newUser: action.newUser,
      };
    }
    case "unset": {
      return { user: undefined };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function AuthProvider({ children }: UserProviderProps) {
  const [state, dispatch] = React.useReducer(AuthReducer, {
    user: undefined,
    userData: undefined,
    newUser: false,
  });

  const value = { state, dispatch };
  return (
    <AuthStateContext.Provider value={value}>
      {children}
    </AuthStateContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
export { AuthProvider, useAuth };
