import * as React from "react";

type Action =
  | { type: "set"; redirectUrl: string; scopes: string[]; params: string[] }
  | { type: "unset" };
type Dispatch = (action: Action) => void;
type State = { redirectUrl: string; scopes: string[]; params: string[] };
type OAuthProviderProps = { children: React.ReactNode };

const OAuthStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function OAuthReducer(state: State, action: Action) {
  switch (action.type) {
    case "set": {
      return {
        redirectUrl: action.redirectUrl || process.env.REACT_APP_REDIRECT_URL,
        scopes: action.scopes,
        params: action.params,
      };
    }
    case "unset": {
      return { redirectUrl: undefined, scopes: [], params: [] };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}
function OAuthProvider({ children }: OAuthProviderProps) {
  const [state, dispatch] = React.useReducer(OAuthReducer, {
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    scopes: [],
    params: [],
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <OAuthStateContext.Provider value={value}>
      {children}
    </OAuthStateContext.Provider>
  );
}
function useOAuth() {
  const context = React.useContext(OAuthStateContext);
  if (context === undefined) {
    throw new Error("useOAuth must be used within a OAuthProvider");
  }
  return context;
}
export { OAuthProvider, useOAuth };
