import { FC } from "react";

import { Button, Grid, Text } from "src/theme";

const GA_ASP_HEADER: string = "asp-header";
const GA_ASP_APP: string = "asp-app";
const GA_ASP_PLAY: string = "asp-play";

export const AppStorePlay: FC<{ gridArea: string }> = ({ gridArea }) => (
  <Grid
    gridArea={gridArea}
    gridTemplateAreas={`
      "${GA_ASP_HEADER} ${GA_ASP_HEADER}"
      "${GA_ASP_APP} ${GA_ASP_PLAY}"
    `}
    alignContent="flex-start"
    gridGap={5}
    width="max-content"
  >
    <Text
      textTransform="capitalize"
      variant="alt"
      fontSize="2xl"
      gridArea={GA_ASP_HEADER}
    >
      Join us in the
      <br />
      metaverse.
      <br />
      Pre-order the
      <br />
      mobile app.
    </Text>
    <Button gridArea={GA_ASP_APP} variant="outline" size="xs">
      <Text>App Store</Text>
    </Button>
    <Button gridArea={GA_ASP_PLAY} variant="outline" size="xs">
      <Text>Google Play</Text>
    </Button>
  </Grid>
);
