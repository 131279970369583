import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  title?: string,
  description?: string,
  keywords?: string
}

const SEO: React.FC<Props> = ({title, description, keywords}) => {

  const LANG = process.env.APP_LANG || "en-us"

  const COPY_DATA = {
    "en-us": {
      title: "Genies | The Fantasy Version of You ",
      description: "As the leading avatar technology company, Genies has created the most intelligent and dynamic avatars on the market. Genies are used by celebrities, tastemakers, and influencers. Genies offers exclusive digital goods and a cavatar SDK.",
      keywords: "avatar technology company"
    },
    "ja": {
      title: "ジーニーズ | ユーザーと著名人のためのカスタム3Dアバター",
      description: "ジーニーズは、アバターテクノロジーのリーディングカンパニーとして、市場で最もインテリジェントでダイナミックなアバターを制作してきました。ジーニーズのアバターは、著名人、トレンドリーダー、インフルエンサーによって使用されています。 ジーニーズは、独占的なデジタル商品と信頼できるアバターSDKを提供しています。",
      keywords: "アバターテクノロジー会社"
    }
  }
  
  // @ts-ignore
  const copy = COPY_DATA[LANG]

  return (
    <Helmet>
      <meta
        name="description"
        content={description || copy.description}
      />
      <meta name="keywords" content={keywords || copy.keywords} />
      <title>{title || copy.title}</title>
    </Helmet>
  )
}

export default SEO