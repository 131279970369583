import React from "react";
import {
  PhoneNumberForm,
  PhoneNumberHeader,
} from "src/modules/PhoneVerification";
import { Box } from "src/theme";

const PhoneVerification = () => {
  return (
    <Box>
      <PhoneNumberHeader />
      <PhoneNumberForm />
    </Box>
  );
};

export default PhoneVerification;
