function baseRange(start, end, step, fromRight) {
  let index = -1;
  let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);
  const result = new Array(length);

  while (length--) {
    result[fromRight ? length : ++index] = start;
    start += step;
  }
  return result;
}

export const range = (start, end, step) => {
  // Ensure the sign of `-0` is preserved.
  if (end === undefined) {
    end = start;
    start = 0;
  }
  step = start < end ? 1 : -1;
  return baseRange(start, end, step);
};
