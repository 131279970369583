const Select = {
  baseStyle: {
    field: {
      bg: "#2F2D2E",
      color: "#fff",
      borderColor: "transparent",
    },
    icon: {
      color: "#fff",
    },
  },
};

export default Select;
