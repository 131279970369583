// eslint-disable-next-line no-restricted-imports
import {
  ComponentSingleStyleConfig,
  ThemeOverride,
  CSSObject,
} from "@chakra-ui/react";
import { Props } from "../Button/Button";

const activeStyles: CSSObject = {
  textDecoration: "none",
  transform: "translate(6px, 6px)",
  _before: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const _Button: ComponentSingleStyleConfig = {
  baseStyle: {
    h: "auto",
    borderRadius: "0px",
    position: "relative",
    fontFamily: "Monument Extended Regular",
    lineHeight: "120%",
    fontWeight: "medium",
    _before: {
      content: '""',
      position: "absolute",
      top: "1.5",
      left: "1.5",
      right: "-1.5",
      bottom: "-1.5",
      borderWidth: "2px",
      borderColor: "brand.primary",
      transform: "translateZ(-1px)",
      transition: "all 0.1s ease-out",
      _hover: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    transformStyle: "preserve-3d",
    _disabled: {
      borderColor: "grey.mid",
      bgColor: "grey.mid",
      color: "white",
      _before: {
        content: "unset",
      },
      _hover: {
        transform: "unset",
      },
      _focus: {
        transform: "unset",
      },
    },
    _hover: activeStyles,
    _focus: activeStyles,
  },
  sizes: {
    sm: {
      px: "2",
      height: "32px",
      fontSize: "xs",
    },
    md: {
      px: "4",
      height: "48px",
      fontSize: "sm",
      width: "min-content",
    },
    lg: {
      px: "4",
      height: "56px",
      fontSize: "md",
    },
    xl: {
      px: "8",
      py: "6",
      height: "64px",
      fontSize: "md",
    },
  },
  variants: {
    solid: ({ colorScheme }: Props) => ({
      bgColor: colorScheme === "grey" ? "grey.4" : "white",
      color: "dark",
    }),
    outline: {
      bgColor: "dark",
      _focus: {
        bgColor: "grey.dark",
      },
      _hover: {
        bgColor: "grey.dark",
        _disabled: {
          bgColor: "grey.mid",
        },
      },
    },
    unstyled: {
      transform: "unset",
      _hover: {
        transform: "unset",
      },
      _focus: {
        transform: "unset",
      },
      _before: {
        display: "none",
      },
    },
  },
  defaultProps: {
    variant: "solid",
    size: "md",
    colorScheme: "black",
  },
};

// We need this hacky TS workaround because the Chakra UI types aren't properly extensible. When we deviate too much from
// the default properties of the `Button` theme, we get false positive type errors.
// https://github.com/chakra-ui/chakra-ui/issues/4497
export const Button = _Button as ThemeOverride["components"]["Button"];
