import { path } from "./utils";

export const home = () => "/drops";

export const storefront = (params = {}) => {
  return path(["storefront"], params);
};

export const nft = (id: string, params = {}) => {
  return path(["wearables", id], params);
};

export const myWearables = (params = {}) => {
  return path(["my-wearables"], params);
};

export const warehouse = (params = {}) => {
  return path(["warehouse"], params);
};

export const warehouseDetail = (id: string, params = {}) => {
  return path(["warehouse", "listing", id], params);
};

const API_PATH = "api";

export const featureFlagsPath = (params = {}) => {
  return path([API_PATH, "flags"], params);
};

const getCognitoApiPath =
  (cognitoPath: string) =>
  (params = {}) =>
    path([API_PATH, "cognito", cognitoPath], params);

export const updateBirthdayPath = getCognitoApiPath("updateBirthday");

export const updateEmailPath = getCognitoApiPath("updateEmail");
export const verifyEmailPath = getCognitoApiPath("verifyEmail");

// TAKEOVERS
export const comingSoon = (params = {}) => {
  return path(["coming-soon"], params);
};

/**
 * Footer - TO-DO: These links go nowhere at the moment.
 */

// BOTTOM_LINKS_PROPS
export const privacy = "https://genies.com/privacy";
export const ccpa = "https://genies.com/ccpa";
export const gdpr = "https://genies.com/gdpr";

// CONNECT_LINKS_PROPS
export const discord = "https://discord.gg/geniesuniverse";
export const instagram = "https://www.instagram.com/genies/";

// TERMS_LINKS_PROPS
export const tos = "https://genies.com/terms-of-service";
export const pp = "https://genies.com/privacy";

// HELP_LINKS_PROPS
export const helpCenter = "https://genies.com/helpCenter";
export const faqs = "https://genies.com/faqs";

// GENIES_LINKS_PROPS
export const about = "https://genies.com/about";
export const developers = "https://genies.com/developers";
export const press = "https://genies.com/press";
export const investors = "https://genies.com/investors";
export const careers = "https://genies.com/careers";
export const hiring = "https://genies.com/hiring";

/**
 * Routes
 */

export const drops = (params = {}) => {
  return path(["drops"], params);
};

export const dropDetails = (id: string, params = {}) => {
  return path(["drops", id], params);
};

export const howItWorks = (params = {}) => {
  return path(["how-it-works"], params);
};

export const listings = (params = {}) => {
  return path(["listings"], params);
};

export const listing = (id: string, params = {}) => {
  return path(["listings", id], params);
};

export const settings = (params = {}) => {
  return path(["settings"], params);
};

export const error = (params = {}) => {
  return path(["error"], params);
};

export const dropEdition = (id: string, params = {}) => {
  return path(["drops", "wearables", id], params);
};

/**
 * Queue paths
 */
export const goToQueuePath = (params = {}) => {
  return path([API_PATH, "purchase", "go-to-queue"], params);
};
// @NOTE: example usage:
// goToQueuePath({
//   eventID: 'devtest01',
//   listingID: NFT.id,
//   nickname: username,
// }),

export const cancelQueuePath = (params = {}) => {
  return path([API_PATH, "purchase", "go-to-queue"], {
    ...params,
    action: "cancel",
  });
};

export const queueCallbackPath = (params = {}) => {
  return path([API_PATH, "purchase", "queue-callback"], params);
};

const getWelcomePath =
  (pathParts: string[]) =>
  (params = {}) =>
    path(["welcome", ...pathParts], params);

/**
 * Onboarding flow
 */
export const begin = getWelcomePath(["begin"]);
export const learn = getWelcomePath(["learn"]);
export const connect = getWelcomePath(["connect"]);
