import React from "react";
import { OTPFooter, OTPFormV2, OTPHeader } from "src/modules/OTP";
import { Box } from "src/theme";

const OTPPage = () => {
  return (
    <Box>
      <OTPHeader />
      <Box my={8}>
        <OTPFormV2 />
      </Box>
      <OTPFooter />
    </Box>
  );
};

export default OTPPage;
