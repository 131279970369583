const Input = {
  parts: ["field", "addon"],
  baseStyle: {
    field: {
      color: "white!important",
      bg: "transparent",
    },
  },
  variants: {
    unstyled: {
      field: {
        // _hover: {
        //   border: "none1px solid",
        //   borderColor: "gray5",
        // },
        _autofill: {
          color: "white!important",
          bg: "#2F2D2E!important",
          fontFamily: "RoobertTrial",
          outline: "none",
          borderColor: "gray5",
          "webkitBoxShadow": "none",
          boxShadow: "none",
        },
        _focus: {
          outline: "none",
          borderColor: "gray5",
          "webkitBoxShadow": "none",
          boxShadow: "none",
          color: "white",
        },
        _placeholder: {
          fontFamily: "RoobertTrial",
          fontSize: 18,
          color: "gray.mid",
          opacity: 0.25,
        },
        _invalid: {
          color: "error",
          borderColor: "error",
        },
        padding: "8px 8px 9px 14px",
        borderRadius: 3,
        color: "white!important",
        bg: "#2F2D2E!important",
        fontFamily: "RoobertTrial",
      },
    },
    solid: {
      color: "white",
    },
  },
  sizes: {
    md: {
      field: {
        py: 6,
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "unstyled",
    errorBorderColor: "error",
  },
};

export default Input;
