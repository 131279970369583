import Auth from "@aws-amplify/auth";
import { nanoid } from "nanoid";
import { useState } from "react";
// import { captureException } from "src/lib/sentry/sentry";
import { useProgress } from "../progress/progress-context";
import { useAuth } from "./auth-context";

const SERVER_ERROR =
  "CreateAuthChallenge failed with error *Too many wrong OTP entries!*.";
const ERROR_MESSAGE =
  "Too many OTP Requests, your account has been suspended for 10 minutes.";

interface ISendOTP {
  to: string;
}

const getUserData = (user) => {
  const { storage, userDataKey } = user;
  if (!storage || !userDataKey || !storage[userDataKey]) {
    return null;
  }
  const userData = JSON.parse(storage[userDataKey]);
  return userData;
};

export const useSendOTP = () => {
  const { dispatch } = useProgress();
  const { dispatch: authDispatch } = useAuth();
  const [value, setValue] = useState<string>("");

  const mutate = async ({ to }: ISendOTP) => {
    try {
      const resp = await Auth.signIn(`${to}`);
      const userData = getUserData(resp);
      authDispatch({ type: "set", user: resp, userData, newUser: false });
      setValue(to);
      dispatch({ type: "increment" });
    } catch (e) {
      if (e.message === SERVER_ERROR) {
        throw ERROR_MESSAGE;
      }
      await Auth.signUp({
        username: `${to}`,
        password: nanoid(),
        attributes: {
          phone_number: `${to}`,
        },
      });
      const resp = await Auth.signIn(`${to}`);
      const userData = getUserData(resp);
      authDispatch({
        type: "set",
        user: resp,
        userData,
        newUser: true,
      });
      setValue(to);
      dispatch({ type: "increment" });
    }
  };

  return {
    mutate,
    state: {
      value,
    },
  };
};
