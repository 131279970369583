import { useOAuth } from "src/lib/oAuth/o-auth-context";
import { useAuth } from "../cognito/auth-context";

export const useRedirect = () => {
  const {
    state: { user },
  } = useAuth();
  const { state: oAuthState } = useOAuth();

  const redirect = async () => {
    const idToken = user.storage[`${user.keyPrefix}.${user.username}.idToken`];
    const refreshToken =
      user.storage[`${user.keyPrefix}.${user.username}.refreshToken`];
    const accessToken =
      user.storage[`${user.keyPrefix}.${user.username}.accessToken`];

    const redirectUrl = `${oAuthState.redirectUrl}?idToken=${idToken}&accessToken=${accessToken}&refreshToken=${refreshToken}`;
    window.location.replace(redirectUrl);
  };

  return {
    redirect,
  };
};
