import * as React from "react";

export enum STEPS {
  "PHONE_NUMBER" = 0,
  "OTP" = 1,
  "BIRTHDAY" = 2,
  "EMAIL" = 3,
  "USERNAME" = 4,
  "TROUBLESHOOTING" = 5,
}

type Action =
  | { type: "increment" }
  | { type: "decrement" }
  | { type: "stepTo"; step: number };
type Dispatch = (action: Action) => void;
type State = { step: number };
type ProgressProviderProps = { children: React.ReactNode };

const ProgressStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function ProgressReducer(state: State, action: Action) {
  switch (action.type) {
    case "increment": {
      return { step: state.step + 1 };
    }
    case "decrement": {
      return { step: state.step - 1 };
    }
    case "stepTo": {
      return { step: action.step };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}
function ProgressProvider({ children }: ProgressProviderProps) {
  const [state, dispatch] = React.useReducer(ProgressReducer, {
    step: STEPS.PHONE_NUMBER,
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <ProgressStateContext.Provider value={value}>
      {children}
    </ProgressStateContext.Provider>
  );
}
function useProgress() {
  const context = React.useContext(ProgressStateContext);
  if (context === undefined) {
    throw new Error("useProgress must be used within a ProgressProvider");
  }
  return context;
}
export { ProgressProvider, useProgress };
