import { Fragment, ReactElement } from "react";
import DefaultNavigation from "src/general/components/Navigations/Default";
import { Footer } from "src/general/components/Footer/Footer";
import { Box, Flex } from "src/theme";

function DefaultLayout({ children }: { children: ReactElement }) {
  return (
    <Fragment>
      <Flex height="100%" flexDirection="column">
        <DefaultNavigation />
        <Box
          flexGrow={1}
          flexShrink={0}
          my={{ base: "10", md: "20" }}
          minHeight={{ base: "40vh", md: "60vh" }}
        >
          {children}
        </Box>
        <Footer />
      </Flex>
    </Fragment>
  );
}

export default DefaultLayout;
