import { FC, Fragment } from "react";

import { privacy, ccpa, gdpr } from "src/modules/router/routes";
import { Box, Flex, Text } from "src/theme";

import { Props as LinkProps } from "react-router-dom";

const BOTTOM_LINKS_PROPS: LinkProps[] = [
  { href: privacy, children: "Privacy Policy" },
  { href: ccpa, children: "CCPA" },
  { href: gdpr, children: "GDPR" },
];

const Circle: FC = () => (
  <Box
    width={0.5}
    height={0.5}
    mx={15}
    bgColor="white"
    borderRadius="50%"
    display="inline-block"
  />
);

export const TermsPolicy: FC = () => (
  <Flex mt={14} gridGap={2} flexFlow="column">
    <Flex align="center">
      {BOTTOM_LINKS_PROPS.map((props, idx) => (
        <Fragment key={props.href}>
          {idx > 0 && <Circle />}
          <a {...props} target="_blank" rel="noreferrer">
            <Text sx={{ cursor: "pointer" }} textStyle="body10">
              {props.children}
            </Text>
          </a>
        </Fragment>
      ))}
    </Flex>
    <Text textStyle="body10" opacity={0.5}>
      United States Patent Pending. © Genies, Inc. All Rights Reserved.
    </Text>
  </Flex>
);
