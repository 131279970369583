import React from 'react';

interface IProps {
  color: string;
}

export const Logo = ({ color }: IProps) => (
  <svg
    width="145"
    height="27"
    viewBox="0 0 145 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.9246 27C17.056 27 20.7966 25.578 22.7227 23.378H22.7506L23.0297 26.5171H28.7802V15.4366H11.0264V18.978H21.7457C21.4944 20.9902 18.7029 22.439 15.074 22.439C10.4122 22.439 7.28577 20.2927 7.28577 17.1C7.28577 13.8 10.0773 11.5732 14.1528 11.5732C16.8047 11.5732 19.0658 12.6463 19.6241 14.2024L26.4074 13.0756C25.57 9.34634 20.5732 6.82439 14.1807 6.82439C5.69463 6.82439 0 11.0366 0 17.3146C0 23.0829 5.19216 27 12.9246 27Z"
        fill={color}
      />
      <path
        d="M42.839 26.9463C46.6913 26.9463 50.376 26.1146 52.3301 24.9073L50.5156 21.9024C49.0361 22.7073 46.1609 23.2976 43.3136 23.2976C40.6059 23.2976 38.0935 22.6537 37.3398 21.0439H52.4138C52.5534 20.5878 52.6092 19.9976 52.6092 19.5951C52.6092 15.1415 48.2824 12.2171 41.7224 12.2171C35.0508 12.2171 30.389 15.2488 30.389 19.5683C30.389 24.0756 35.4137 26.9463 42.839 26.9463ZM37.2282 18.3341C37.4794 16.778 39.2938 15.678 41.6666 15.678C43.9835 15.678 45.8818 16.7512 45.9655 18.3341H37.2282Z"
        fill={color}
      />
      <path
        d="M68.6773 12.2171C65.0763 12.2171 62.3406 13.7195 61.0007 16.0268H60.9728L60.2749 12.6732H54.5803V26.5171H61.1682V20.0244C61.1682 17.3951 62.871 15.839 65.6625 15.839C68.119 15.839 69.5426 17.1537 69.5426 19.3537V26.5171H76.1026L76.1305 18.6829C76.1585 14.6854 73.2553 12.2171 68.6773 12.2171Z"
        fill={color}
      />
      <path
        d="M81.5118 11.1976C83.6333 11.1976 85.1686 9.90976 85.1686 8.08537C85.1686 6.26098 83.6333 5 81.5118 5C79.3903 5 77.8549 6.26098 77.8549 8.08537C77.8549 9.90976 79.3903 11.1976 81.5118 11.1976ZM78.2178 26.5171H84.8057V12.6732H78.2178V26.5171Z"
        fill={color}
      />
      <path
        d="M99.1322 26.9463C102.984 26.9463 106.669 26.1146 108.623 24.9073L106.809 21.9024C105.329 22.7073 102.454 23.2976 99.6067 23.2976C96.899 23.2976 94.3866 22.6537 93.6329 21.0439H108.707C108.847 20.5878 108.902 19.9976 108.902 19.5951C108.902 15.1415 104.576 12.2171 98.0156 12.2171C91.3439 12.2171 86.6821 15.2488 86.6821 19.5683C86.6821 24.0756 91.7068 26.9463 99.1322 26.9463ZM93.5213 18.3341C93.7725 16.778 95.587 15.678 97.9597 15.678C100.277 15.678 102.175 16.7512 102.259 18.3341H93.5213Z"
        fill={color}
      />
      <path
        d="M121.146 26.9463C126.785 26.9463 131 25.4171 131 21.9561C131 19.1927 128.348 18.0122 123.072 17.6366L119.499 17.3415C117.768 17.2073 116.903 16.9927 116.903 16.4561C116.903 15.7585 118.69 15.4098 121.006 15.4098C123.603 15.4098 126.338 16.0805 128.125 16.9659L129.688 14.0415C127.148 12.8341 124.133 12.2171 121.09 12.2171C115.424 12.2171 111.32 13.7732 111.32 17.2341C111.32 19.7561 113.804 20.8829 118.662 21.2049L122.235 21.4732C124.217 21.6073 125.25 21.9024 125.25 22.4659C125.25 23.1098 123.854 23.539 120.727 23.539C117.461 23.539 113.832 22.6805 111.878 21.5268L110.148 24.7463C112.576 26.061 117.015 26.9463 121.146 26.9463Z"
        fill={color}
      />
      <path
        d="M135.5 -1C135.5 4.7 139.3 8.5 145 8.5C139.3 8.5 135.5 12.3 135.5 18C135.5 12.3 131.7 8.5 126 8.5C131.7 8.5 135.5 4.7 135.5 -1Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="145" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
