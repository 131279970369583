import { Box } from "src/theme";
import { Logo } from "src/modules/Brand/Logo";

const DefaultNavigation = () => {
  return (
    <Box
      display="flex"
      sx={{
        height: "70px",
        alignItems: "center",
        px: 6,
        py: 6,
      }}
      justifyContent={["center", "space-between"]}
      w="100vw"
      bg="transparent"
    >
      <Logo color="white" />
    </Box>
  );
};

export default DefaultNavigation;
