// eslint-disable-next-line no-restricted-imports
import { ThemeOverride } from "@chakra-ui/react";

const Text: ThemeOverride["components"]["Text"] = {
  baseStyle: {
    color: "white",
  },
  variants: {
    alt: {
      fontFamily: "Monument Extended Regular",
      lineHeight: 1.1,
    },
    // @TODO: this will fail on internet explorer. probably need to use emotion +
    // @supports to test this, like:
    /*
      @supports (-webkit-text-stroke: 1px white) {
        x {
          -webkit-text-stroke: 1px white;
          -webkit-text-fill-color: white;
        }
      }
    */
    outline: {
      WebkitTextStroke: "1px white",
      color: "transparent",
    },

    link: {
      cursor: "pointer",
      textTransform: "capitalize",
    },
    inactive: {
      color: "gray2",
      cursor: "not-allowed",
      textTransform: "capitalize",
    },
    subtitle: {
      opacity: 0.4,
    },
    sparkle: {
      bg: 'top right no-repeat url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuNSAwYzAgMy45IDIuNiA2LjUgNi41IDYuNS0zLjkgMC02LjUgMi42LTYuNSA2LjUgMC0zLjktMi42LTYuNS02LjUtNi41IDMuOSAwIDYuNS0yLjYgNi41LTYuNXoiIGZpbGw9IiM2RjU3RkYiLz48L3N2Zz4=")',
      pr: 4,
      pt: 1,
    },
  },
};

export default Text;
