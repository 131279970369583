import React from "react";
import { ReactComponent as StarSVG } from "src/general/components/StarSVG/StarSVG.svg";
import { ReactComponent as WaveSVG } from "src/general/components/WaveSVG/WaveSVG.svg";
import { Center, Heading, Text } from "src/theme";
import theme from "src/theme/theme";
import { LANDING_HEADER, LANDING_SUBHEADER } from "./constants";

const LoginHeader = () => (
  <>
    <Center mb={8}>
      <StarSVG stroke={theme.colors.pureWhite.toString()} />
    </Center>
    <Heading data-testid="phone-header" textAlign="center">{LANDING_HEADER}</Heading>
    <Center my={8}>
      <WaveSVG stroke={theme.colors.secondaryBrand.toString()} />
    </Center>
    <Text data-testid="phone-subheader" mt={2} textAlign="center">
      {LANDING_SUBHEADER}
    </Text>
  </>
);

export default LoginHeader;
