import { FC } from "react";

import { Logo } from "src/modules/Brand/Logo";
import { Box, Container, Grid, GridProps } from "src/theme";

import { AppStorePlay } from "./AppStorePlay";
import { LinksSection } from "./LinksSection";
import { TermsPolicy } from "./TermsPolicy";

const GA_FOOTER_LOGO: string = "footer-logo";
const GA_FOOTER_APPSTOREPLAY: string = "footer-appstoreplay";
const GA_FOOTER_LINKS: string = "footer-links";

const FooterGridStyles: Pick<
  GridProps,
  "gridRowGap" | "gridColumnGap" | "gridTemplateColumns" | "gridTemplateAreas"
> = {
  gridRowGap: 14,
  gridColumnGap: 10,
  gridTemplateColumns: {
    base: "min-content",
    sm: "min-content 1fr",
    lg: "min-content 1fr 1fr",
  },
  gridTemplateAreas: {
    base: `
      "${GA_FOOTER_LOGO}"
      "${GA_FOOTER_APPSTOREPLAY}"
      "${GA_FOOTER_LINKS}"
    `,
    sm: `
      "${GA_FOOTER_LOGO} ${GA_FOOTER_APPSTOREPLAY}"
      "${GA_FOOTER_LINKS} ${GA_FOOTER_LINKS}"
    `,
    lg: `
      "${GA_FOOTER_LOGO} ${GA_FOOTER_APPSTOREPLAY} ${GA_FOOTER_LINKS}"
    `,
  },
};

export const Footer: FC = () => (
  <Box mt={30} paddingBottom={30} as="footer">
    <Container>
      <Grid {...FooterGridStyles}>
        <Box gridArea={GA_FOOTER_LOGO}>
          <Logo color="white" />
        </Box>
        <AppStorePlay gridArea={GA_FOOTER_APPSTOREPLAY} />
        <LinksSection gridArea={GA_FOOTER_LINKS} />
      </Grid>
      <TermsPolicy />
    </Container>
  </Box>
);
