import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { HStack, Text, Button, Input, FormControl } from "src/theme";
import { useUpdateUsername } from "src/lib/cognito/useUpdateUsername";

type Inputs = {
  username: string;
};

const UsernameForm = () => {
  const [loading, setLoading] = React.useState(false);
  const { mutate: updateUsername } = useUpdateUsername();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<Inputs>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<Inputs> = ({ username }) => {
    setLoading(true);
    updateUsername({ username }).then(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HStack w="full" mt={10}>
        <FormControl.Label>Username</FormControl.Label>
        <Input
          data-testid="username-input"
          isInvalid={!!errors.username}
          placeholder="Username"
          type="text"
          {...register("username", { required: true })}
        />
      </HStack>
      {errors.username && (
        <Text mt={2} color="error">
          {errors.username.message}
        </Text>
      )}
      <Button
        data-testid="submit-button"
        mt={4}
        width="full"
        disabled={!isValid}
        variant="solid"
        type="submit"
        isLoading={loading}
      >
        Continue
      </Button>
    </form>
  );
};

export default UsernameForm;
