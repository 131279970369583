const Checkbox = {
    baseStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FEFEFE',
        opacity: '0.6',
        label: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'RoobertTrial',
            fontSize: '14px',
            color: 'rgba(254, 254, 254, 0.6)',
        },
        control: {
            _checked: {
                bg: 'transparent',
                borderColor: 'pureWhite',
                _hover: {
                    bg: 'transparent',
                    borderColor: 'pureWhite',
                },
                "data-hover": {
                    bg: 'transparent',
                    borderColor: 'pureWhite',
                },
            },
            "data-checked": {
                bg: 'transparent',
                borderColor: 'pureWhite',
                _hover: {
                    bg: 'transparent',
                    borderColor: 'pureWhite',
                },
                "data-hover": {
                    bg: 'transparent',
                    borderColor: 'pureWhite',
                },
            },
            _hover: {
                bg: 'transparent',
                borderColor: 'pureWhite',
            },
            "data-hover": {
                bg: 'transparent',
                borderColor: 'pureWhite',
            },
            _focus: {
                boxShadow: 'none',
            },
            "data-focus": {
                boxShadow: 'none',
            },
        }
    },
}

export default Checkbox