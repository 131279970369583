const Heading = {
  baseStyle: {
    fontWeight: "700",
    fontSize: "32px",
    color: "pureWhite",
    textTransform: 'uppercase',
    lineHeight: '110%',
    letterSpacing: 2
    
  },
  sizes: {
    sm: {
      fontSize: "sm",
    },
    md: {
      fontSize: "md",
    },
    "2xl": {
      fontSize: "2xl",
    },
  },
  defaultProps: {
    size: "2xl",
  },
};

export default Heading