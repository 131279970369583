import React from "react";
// import { useAnalytics } from "src/lib/segment";
// eslint-disable-next-line no-restricted-imports
import {
  ButtonProps,
  ComponentWithAs,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { ComponentProps, useCallback } from "react";

interface TrackingProperties {
  trackingId?: SVGStringList;
  trackingProperties?: object;
  onClick?: (e: any) => void;
}

export const Button: ComponentWithAs<
  "button",
  ButtonProps & TrackingProperties
> = ({ trackingId, trackingProperties = {}, onClick = () => {}, ...props }) => {
  // const { track } = useAnalytics();
  const hoistedOnClick = useCallback(
    (e) => {
      // if (!!trackingId) {
      //   track(trackingId, trackingProperties);
      // }
      onClick(e);
    },
    // [track, trackingId, trackingProperties, onClick]
    [onClick]
  );

  return <ChakraButton onClick={hoistedOnClick} {...props} />;
};

export type Props = ComponentProps<typeof Button>;
