import React from "react";
import { Center, Text, HStack } from "src/theme";

const OTPFooter = () => {
  const onClick = () => {};

  return (
    <Center>
      <HStack>
        <Text color="black">Didn’t receive a code?</Text>
        <Text
          color="black"
          cursor="pointer"
          onClick={onClick}
          sx={{ fontWeight: "bold" }}
        >
          Resend
        </Text>
      </HStack>
    </Center>
  );
};

export default OTPFooter;
