import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

// Styles
import "./App.css";
import { Container } from "./theme";

// Pages
import OTPPage from "./pages/OTP";
import PhoneVerification from "./pages/phoneVerification";
import MarketingPage from "./pages/marketing";
import TroubleshootingPage from "./pages/troubleshooting";
import BirthdayPage from "./pages/birthday";
import UsernamePage from "./pages/username";

// Libs
import { STEPS, useProgress } from "./lib/progress/progress-context";
import { useOAuth } from "./lib/oAuth/o-auth-context";
import SEO from "./general/components/SEO/SEO";

const ContainerStyle = {
  my: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function App() {
  const location = useLocation();
  let params = queryString.parse(location.search);
  const { dispatch } = useOAuth();
  const { state: progressState } = useProgress();

  useEffect(() => {
    dispatch({
      type: "set",
      redirectUrl: params.redirectURL,
      scopes: params.scopes,
      params: params.params,
    });
  }, [dispatch, params.redirectURL, params.scopes, params.params]);

  let component;
  switch (progressState.step) {
    case STEPS.PHONE_NUMBER: {
      component = <PhoneVerification />;
      break;
    }
    case STEPS.OTP: {
      component = <OTPPage />;
      break;
    }
    case STEPS.USERNAME: {
      component = <UsernamePage />;
      break;
    }
    case STEPS.TROUBLESHOOTING: {
      component = <TroubleshootingPage />;
      break;
    }
    default:
      component = <PhoneVerification />;
      break;
  }

  return (
    <>
      <SEO />
      <Container maxWidth="md" sx={ContainerStyle}>
        {component}
      </Container>
    </>
  );
}

export default App;
