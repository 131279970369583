import { FC } from "react";
import { Props as LinkProps } from "react-router-dom";
import { Flex, Text } from "src/theme";

export interface LinkColumnProps {
  title: string;
  linksProps: LinkProps[];
  gridArea: string;
}

export const LinkColumn: FC<LinkColumnProps> = ({
  gridArea,
  title,
  linksProps,
}) => (
  <Flex direction="column" gridGap={5} gridArea={gridArea}>
    <Text
      color="white"
      textTransform="uppercase"
      textStyle="body14"
      opacity={0.5}
      letterSpacing="0.3em"
    >
      {title}
    </Text>
    {linksProps.map((props, index) => (
      <a key={index} href={props.to} target="_blank" rel="noreferrer">
        <Text sx={{ cursor: "pointer" }} color="white" textStyle="body14">
          {props.children}
        </Text>
      </a>
    ))}
  </Flex>
);
