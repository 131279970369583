import React from "react";
import { ReactComponent as StarSVG } from "src/general/components/StarSVG/StarSVG.svg";
import { ReactComponent as WaveSVG } from "src/general/components/WaveSVG/WaveSVG.svg";
import { Center, Heading, Text } from "src/theme";
import theme from "src/theme/theme";
import { USERNAME_HEADER, USERNAME_SUBHEADER } from "./constants";

const UsernameHeader = () => {
  return (
    <>
      <Center mb={8}>
        <StarSVG stroke={theme.colors.pureWhite.toString()} />
      </Center>
      <Heading textAlign="center">{USERNAME_HEADER}</Heading>
      <Center my={8}>
        <WaveSVG stroke={theme.colors.secondaryBrand.toString()} />
      </Center>
      <Text mt={2} textAlign="center">
        {USERNAME_SUBHEADER}
      </Text>
    </>
  );
};

export default UsernameHeader;
