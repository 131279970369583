import { Global } from "@emotion/react";

export default function GlobalStyles() {
  return (
    <Global
      styles={() => ({
        "*": {
          boxSizing: "border-box",
        },
        "html, body, #root": {
          height: "100vh",
          background: "black",
        },
      })}
    />
  );
}
