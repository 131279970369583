import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import { AnalyticsProvider } from '@dapperlabs/react-analytics';
import { ChakraProvider } from "@chakra-ui/react";

import "./index.css";
import "./lib/sentry/sentry";
import "src/lib/cognito/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme/theme";
import GlobalStyles from "./theme/globalStyles";
import LayoutWrapper from "./general/components/Layouts/LayoutWrapper";
import { PhoneNumberProvider } from "./lib/phoneNumber/phoneNumber-context";
import { ProgressProvider } from "./lib/progress/progress-context";
import { OAuthProvider } from "./lib/oAuth/o-auth-context";
import { AuthProvider } from "./lib/cognito/auth-context";
import { Fonts } from "./theme/Fonts/Fonts";
import { createRoot } from 'react-dom/client';

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Fonts />
      <OAuthProvider>
        <ProgressProvider>
          <AuthProvider>
            <PhoneNumberProvider>
              <GlobalStyles />
              <Router>
                <LayoutWrapper>
                  <App />
                </LayoutWrapper>
              </Router>
            </PhoneNumberProvider>
          </AuthProvider>
        </ProgressProvider>
      </OAuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
