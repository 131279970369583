import { FC } from "react";

import {
  discord,
  instagram,
  helpCenter,
  faqs,
  about,
  developers,
  press,
  investors,
  careers,
  hiring,
} from "src/modules/router/routes";
import { Grid, GridProps } from "src/theme";
import { Props as LinkProps } from "react-router-dom";
import { LinkColumn, LinkColumnProps } from "./LinkColumn";

const GA_LINKCOL_CONNECT: string = "linkcol-connect";
const GA_LINKCOL_HELP: string = "linkcol-help";
const GA_LINKCOL_GENIES: string = "linkcol-genies";

const CONNECT_LINKS_PROPS: LinkProps[] = [
  { to: discord, children: "Discord", isExternal: true },
  { to: instagram, children: "Instagram", isExternal: true },
];

const HELP_LINKS_PROPS: LinkProps[] = [
  { to: helpCenter, children: "Help Center" },
  { to: faqs, children: "FAQs" },
];

const GENIES_LINKS_PROPS: LinkProps[] = [
  { to: about, children: "About", isExternal: true },
  { to: developers, children: "Developers", isExternal: true },
  { to: press, children: "Press", isExternal: true },
  { to: investors, children: "Investors", isExternal: true },
  { to: careers, children: "Careers", isExternal: true },
  { to: hiring, children: "We're Hiring", isExternal: true },
];

const LINK_COLUMNS: LinkColumnProps[] = [
  {
    gridArea: GA_LINKCOL_CONNECT,
    title: "Connect",
    linksProps: CONNECT_LINKS_PROPS,
  },
  {
    gridArea: GA_LINKCOL_HELP,
    title: "Help",
    linksProps: HELP_LINKS_PROPS,
  },
  {
    gridArea: GA_LINKCOL_GENIES,
    title: "Genies",
    linksProps: GENIES_LINKS_PROPS,
  },
];

const LinksGridStyles: Pick<
  GridProps,
  "gridRowGap" | "gridColumnGap" | "gridTemplateColumns" | "gridTemplateAreas"
> = {
  gridRowGap: 14,
  gridColumnGap: {
    base: 20,
    sm: 0,
  },
  gridTemplateColumns: {
    base: "repeat(2, max-content)",
    sm: "1fr 2fr 1fr 2fr 1fr",
  },
  gridTemplateAreas: {
    base: `
      "${GA_LINKCOL_GENIES} ${GA_LINKCOL_CONNECT}"
      "${GA_LINKCOL_HELP} ."
    `,
    sm: `
      "${GA_LINKCOL_CONNECT} . ${GA_LINKCOL_HELP} . ${GA_LINKCOL_GENIES}"
    `,
  },
};

export const LinksSection: FC<{ gridArea: string }> = ({ gridArea }) => (
  <Grid gridArea={gridArea} {...LinksGridStyles}>
    {LINK_COLUMNS.map(({ gridArea, title, linksProps }) => (
      <LinkColumn
        key={gridArea}
        gridArea={gridArea}
        title={title}
        linksProps={linksProps}
      />
    ))}
  </Grid>
);
