import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRedirect } from "src/lib/oAuth/useRedirect";
import { useAuth } from "src/lib/cognito/auth-context";
import { useResend } from "src/lib/cognito/useResend";
import { useVerifyOTP } from "src/lib/cognito/useVerifyOTP";
import {
  PinInput,
  PinInputField,
  Button,
  HStack,
  Center,
  Text,
} from "src/theme";
import theme from "src/theme/theme";
import { STEPS, useProgress } from "src/lib/progress/progress-context";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const RESEND_TIMER = 10;

type Inputs = {
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  otp5: string;
  otp6: string;
};

const getBackground = (value, allValues, error) => {
  if (error) return `2px solid ${theme.colors.error}!important`;
  if (allValues) return `2px solid ${theme.colors.primaryBrand}!important`;
  if (value !== null) return `2px solid ${theme.colors.pureWhite}`;
  return "none";
};

const getPinInputStyle = (value, allValues, error) => ({
  border: getBackground(value, allValues, error),
  background: Number.isInteger(value) ? "transparent" : "#2F2D2E",
  borderRadius: "3px",
  color: "pureWhite",
  _focus: {
    outline: "none",
    "webkitBoxShadow": "none",
    boxShadow: "none",
  },
});

const getBirthday = (user) => {
  if (!user) return null;
  const { UserAttributes } = user;
  const birthday = UserAttributes.find(
    ({ Name }) => Name === "custom:birthday"
  );
  return birthday ? birthday.Value : null;
};

const getEmail = (user) => {
  if (!user) return null;
  const { UserAttributes } = user;
  const birthday = UserAttributes.find(({ Name }) => Name === "email");
  return birthday ? birthday.Value : null;
};

const getResendText = (attempts) => {
  switch (attempts) {
    case 0:
    case 1:
      return "Didn't Receive a code? Resend ";
    case 2:
      return "Didn't Receive a code? Call me instead ";
    case 3:
      return "Trouble getting your code? ";
    default:
      return "Didn't Receive a code? Resend ";
  }
};

const OTPFormV2 = () => {
  const [loading, setLoading] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(true);
  const [error, setError] = React.useState("");
  const [numberOfMistakes, setNumberOfMistakes] = React.useState(0);
  const [numberOfRetries, setNumberOfRetries] = React.useState(0);
  const [seconds, setSeconds] = React.useState(RESEND_TIMER);
  const { redirect } = useRedirect();
  const { mutate: resendOTP } = useResend();
  const { mutate: verifyOTP, state: verifyState } = useVerifyOTP();
  const { state: authState } = useAuth();
  const { dispatch: progressDispatch } = useProgress();

  const { handleSubmit, register } = useForm<Inputs>({
    mode: "onChange",
  });

  React.useEffect(() => {
    let interval = null;
    if (seconds === 0) {
      clearInterval(interval);
      setIsRunning(false);
    } else {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  React.useEffect(() => {
    if (verifyState.value === verifyState.context.FS.ERROR) {
      setError("Please enter a valid verification code");
      if (numberOfMistakes === 5) {
        setError(
          "Invalid Code. One more failed attempt will result in your account being locked for 10 minutes."
        );
      }
    } else if (verifyState.value === verifyState.context.FS.SUCCESS) {
      redirect();
    }
  }, [
    verifyState.value,
    authState.newUser,
    authState.userData,
    progressDispatch,
    redirect,
    numberOfMistakes,
    verifyState.context.FS.ERROR,
    verifyState.context.FS.SUCCESS,
  ]);

  React.useEffect(() => {
    if (numberOfMistakes === 6) {
      progressDispatch({ type: "stepTo", step: STEPS.PHONE_NUMBER });
    }
  }, [numberOfMistakes, progressDispatch]);

  const resetTimer = () => {
    setSeconds(RESEND_TIMER);
    setIsRunning(true);
  };

  // On resend handler
  const onResend = () => {
    if (numberOfRetries === 3) {
      progressDispatch({ type: "stepTo", step: STEPS.TROUBLESHOOTING });
      return;
    }
    resetTimer();
    resendOTP();
    setNumberOfRetries(numberOfRetries + 1);
  };


  const [otpValue, setOtpValue] = React.useState('');

  const otp1 = React.useMemo(() => parseInt(otpValue?.charAt(0)), [
    otpValue,
  ]);
  const otp2 = React.useMemo(() => parseInt(otpValue?.charAt(1)), [
    otpValue,
  ]);
  const otp3 = React.useMemo(() => parseInt(otpValue?.charAt(2)), [
    otpValue,
  ]);
  const otp4 = React.useMemo(() => parseInt(otpValue?.charAt(3)), [
    otpValue,
  ]);
  const otp5 = React.useMemo(() => parseInt(otpValue?.charAt(4)), [
    otpValue,
  ]);
  const otp6 = React.useMemo(() => parseInt(otpValue?.charAt(5)), [
    otpValue,
  ]);

  // On submit handler
  const onSubmit: SubmitHandler<Inputs> = () => {
    setLoading(true);
    setError("");
    verifyOTP({ user: authState.user, code: otpValue })
      .then((resp) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setNumberOfMistakes((numberOfMistakes) => numberOfMistakes + 1);
      });
  };
  
  // Check if all OTP fields are filled
  const all = otpValue.length === 6;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Center display="flex" flexDirection="column">
        <HStack>
          <PinInput
              otp
              autoFocus
              manageFocus
              onChange={(value) => setOtpValue(value)}
            >
            <PinInputField
              data-testid="otp-input-1"
              sx={getPinInputStyle(otp1, all, error)}
              value={otp1}
              {...register('otp1')}
            />
            <PinInputField
              data-testid="otp-input-2"
              sx={getPinInputStyle(otp2, all, error)}
              value={otp2}
              {...register('otp2')}
            />
            <PinInputField
              data-testid="otp-input-3"
              sx={getPinInputStyle(otp3, all, error)}
              value={otp3}
              {...register('otp3')}
            />
            <PinInputField
              data-testid="otp-input-4"
              sx={getPinInputStyle(otp4, all, error)}
              value={otp4}
              {...register('otp4')}
            />
            <PinInputField
              data-testid="otp-input-5"
              sx={getPinInputStyle(otp5, all, error)}
              value={otp5}
              {...register('otp5')}
            />
            <PinInputField
              data-testid="otp-input-6"
              sx={getPinInputStyle(otp6, all, error)}
              value={otp6}
              {...register('otp6')}
            />
          </PinInput>
        </HStack>
        {error && (
          <Text textAlign="center" mt={2} color="error">
            {error}
          </Text>
        )}
      </Center>
      <Button
        data-testid="otp-submit-button"
        mt={10}
        width="full"
        disabled={!all}
        variant="solid"
        type="submit"
        isLoading={loading}
      >
        Verify Account
      </Button>
      <Center mt={10}>
        <Text
          onClick={isRunning ? () => {} : onResend}
          variant={isRunning ? "inactive" : "link"}
        >
          {getResendText(numberOfRetries)}
          {isRunning ? `(${seconds}s)` : <ArrowForwardIcon />}
        </Text>
      </Center>
    </form>
  );
};

export default OTPFormV2;
