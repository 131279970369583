import { useState } from "react";
import { Auth } from "aws-amplify";
// import { captureException } from "server/sentry";
import { useProgress } from "../progress/progress-context";
import { FS, FSKey } from "src/general/constants/finiteStates";
import { useAuth } from "./auth-context";

export const useUpdateUsername = () => {
  const { dispatch } = useProgress();
  const [value, setValue] = useState<FSKey>(FS.IDLE);
  const { state } = useAuth();

  const mutate = async ({ username }) => {
    setValue(FS.LOADING);

    try {
      const resp = await Auth.updateUserAttributes(state.user, {
        "custom:username": username,
      });
      if (resp) {
        setValue(FS.SUCCESS);
        dispatch({ type: "increment" });
      } else {
        throw new Error("Error Updating Email");
      }
    } catch (e) {
      setValue(FS.ERROR);
      //   captureException(e);
    }
  };

  return {
    mutate,
    state: {
      value,
      context: {
        FS,
      },
    },
  };
};
