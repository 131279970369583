import React from "react";
import { UsernameForm, UsernameHeader } from "src/modules/Username";
import { Box } from "src/theme";

const UsernamePage = () => {
  return (
    <Box>
      <UsernameHeader />
      <UsernameForm />
    </Box>
  );
};

export default UsernamePage;
